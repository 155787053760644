/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import React from 'react';
import Layout from '../components/layout';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import Container from '../components/Container';
import * as analytics from '../utils/analytics';

const ThankYouPage = ({ pageContext, location }) => {
  analytics.usePageCategory('lomakkeet');
  const localize = useSelector(state => state.localize);
  const translate = getTranslate(localize);

  return (
    <Layout
      title={translate('thankYou')}
      paths={pageContext.paths}
      locale={pageContext.locale || 'en'}
    >
      <Container variant="parcel" sx={{ py: [3, 4] }}>
        <Styled.h1>{translate('thankYou')}</Styled.h1>
          <Styled.p>{translate('thankYouHandleRequest')}</Styled.p>
          <Styled.p>{translate('thankYouCordially')}
          <br />Matkahuolto
          </Styled.p>
      </Container>
    </Layout>
  );
};

export default ThankYouPage;
